<template>
  <div class="ma-2 buttons-holder">
    <div class="buttons-shell d-flex">
      <v-btn small v-ripple="false" text class="buttons flex-grow-1" @click="changeMethod('register')"
             :class="{'white--text':method=='register','active-btn':method=='register','inactive-btn':method!='register'}">
        <v-icon class="mx-1" x-small>fas fa-plus</v-icon>
        <span ref="register-text" class="">{{ $t('$vuetify.onBoarding.wizardLogin[0]') }}</span>
      </v-btn>

      <v-btn small v-ripple="false" text class="buttons flex-grow-1" @click="changeMethod('login')"
             :class="{'white--text':method=='login','active-btn':method=='login','inactive-btn':method!='login'}">
        <span ref="login-text" style="font-size: 10px !important;">{{ $t('$vuetify.onBoarding.wizardLogin[1]') }}</span>
        <div style="width: 15px;height: 15px;" :class="{'mr-1':method=='login','ml-2':method!='login'}">
          <img ref="login-icon" :src="require('../../assets/new-design/enter-white.svg')"
               width="15px;" alt="">
          <img ref="register-icon" :src="require('../../assets/new-design/enter.svg')"
               width="15px;" alt="">
        </div>

      </v-btn>
      <div class="switch " ref="switch"></div>
    </div>
  </div>

</template>

<script>
import gsap from 'gsap';

export default {
  props: {
    activeBtn: {
      default: 'register'
    }
  },
  mounted() {

    this.changeMethod(this.method)
    this.moveText(this.method, 'login');

  },
  computed: {
    position() {
      return this.method == 'login' ? 0 : 30;
    }
  },
  data() {
    return {
      method: 'register'
    }
  },
  methods: {
    moveText(method, target) {
      gsap.fromTo(this.$refs[target + '-text'], {
        opacity: 1,
        width: '100px',
        duration: .1
      }, {
        opacity: 0,
        width: 0,
        duration: .4,
      })
      gsap.fromTo(this.$refs[method + '-text'], {
        opacity: 0,
        width: 0,
        duration: .1
      }, {
        opacity: 1,
        duration: .4,
        width: 'auto',
      })
    },
    changeMethod(method, emit = true) {
      this.method = method;
      let target = method == 'login' ? 'register' : 'login';
      let tl = new gsap.timeline();

      tl.fromTo(this.$refs[target + '-icon'], {
        opacity: 1,
        display: 'block',
        duration: 0
      }, {
        opacity: 0,
        display: 'none',
        duration: .2
      })
      tl.fromTo(this.$refs[method + '-icon'], {
        opacity: 0,
        display: 'none',
        duration: 0
      }, {
        opacity: 1,
        display: 'block',
        duration: .2
      })

      if (this.$vuetify.rtl) {
        gsap.to(this.$refs.switch, .3, {
          left: target == 'register' ? 0 : 30 + '%',
          callback: () => {
            if (!emit) {
              this.moveText(method, target);
            }

          }
        }, '<')
      } else {
        gsap.to(this.$refs.switch, .3, {
          right: target == 'register' ? 0 : 30 + '%',
          callback: () => {
            if (!emit) {
              this.moveText(method, target);
            }

          }
        }, '<')
      }

      if (emit)
        this.$emit('changeMethod', this.method)
    }
  },
  watch: {
    activeBtn(val) {
      this.changeMethod(val, false)
    }
  }
}
</script>

<style scoped>
.buttons-shell {

  border-radius: 10px;
  border: solid 1px #20b770;
  overflow: hidden;
  width: 150px !important;
  position: relative;

}

.buttons-holder {
  width: 150px;
}

.buttons {
  z-index: 2 !important;
  /*width: 100%;*/
  /*background-color: red;*/
  transition: all .3s ease-in-out;
  border-radius: 3px !important;
  color: #20b770;
}

.switch {
  width: 70%;
  height: 100%;
  background-color: #20b770;
  border-radius: 10px;
  position: absolute;
  /*transition: left .3s ease-in-out;*/
  top: 0;
  z-index: 1 !important;
}

.en-switch {
  left: 0 !important;
}

.enter-icon {
  border: 2px solid white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  /*width: 10px;*/
  height: 17px;
  border-right: none;

}

.active-btn {
  width: 70% !important;
}

.inactive-btn {
  width: 30% !important;
  /*background-color: yellow;*/
}
</style>