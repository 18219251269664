<template>
  <div>
    <v-dialog v-model="showDialog" max-width="80%" v-if="!isMobile">
      <v-card elevation="0" style="border-top-right-radius: 20px!important;border-top-left-radius: 20px!important;">
        <div class="bottom-modal-header-container">
          <div class="bottom-modal-header-content mt-4">
            شرایط و قوانین سیمیاروم
          </div>
        </div>
        <v-card-text class="background-gray">
          <div style="background-color: #f7f7f7;">
            <div v-for="(section,n) in sections" :key="n" >
              <h4 >{{ section.title }}</h4>
              <p style="color: #6d6d6d;">{{ section.body }}</p>
            </div>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <template v-else>
      <v-fade-transition>
      <div class="overlay" v-show="showDialog" @click.self="closeModal" ref="overlay">
        <div class="bottom-modal" ref="bottomModal">
          <div class="bottom-modal-header-container">
            <!--            <div class="bottom-modal-header-handle" @click="closeModal"></div>-->
            <div class="bottom-modal-header-content">
              شرایط و قوانین سیمیاروم
            </div>
          </div>
          <div class="modal-content" style="text-align: right;">
            <div class="content-shell">
              <div v-for="(section,n) in sections" :key="n">
                <h4>{{ section.title }}</h4>
                <p class="mt-2">{{ section.body }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      </v-fade-transition>
    </template>
  </div>

</template>

<script>
/*eslint-disable*/

import gsap from 'gsap';

export default {
  methods: {
    closeModal() {
      if (this.isMobile) {
        gsap.to(this.$refs.bottomModal, .5, {
          bottom: '-80%',
          // onComplete: () => {
          //   this.$emit('modalClosed')
          //   this.showDialog = false;
          // }
        })
        setTimeout(() => {
          this.$emit('modalClosed')
          this.showDialog = false;
        }, 300)
      } else {

      }
    }
  },
  props: {
    showModal: {
      default: false
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        if (this.isMobile) {
          console.log(this.$refs.bottomModal)
          gsap.to(this.$refs.bottomModal, .5, {
            bottom: '0%'
          })
        }
      } else {
        if (!this.isMobile) {
          this.$emit('modalClosed')
        }
      }
    },
    showModal(val) {
      if (val) {
        this.showDialog = true;
      }
    }
  },
  data() {
    return {
      showDialog: false,
      sections: [
        {
          title: 'همراه عزیز سلام',
          body: `
          متشکریم که به سیمیاروم اعتماد کرده و روانشناسان و روانپزشکان ما را برای برگزاری جلسات مشاوره خود انتخاب
          کرده‌اید. خواهشمندیم پیش از رزرو جلسات، شرایط و ضوابط مربوط به مشاوره آنلاین در سیمیاروم را با دقت مطالعه کرده
          و
          سپس اقدام به رزرو کنید.`
        },
        {
          title: 'حفظ حریم خصوصی', body: `کلیه‌ اطلاعات خصوصی مراجعه‌کننده بین او و مشاور باقی خواهد ماند و درمانگر از نظر اخلاقی و قانونی و طبق اصل
          رازداری، موظف است حافظ این اطلاعات باشد؛ مگر در شرایطی که به نظر برسد درمانجو ممکن است به خود یا دیگران آسیب
          برساند. در این صورت روانشناس می‌تواند و به لحاظ قانونی حق دارد که این اطلاعات را در اختیار مَراجع قانونی قرار
          دهد. بدیهی است درمانگر پیش از این‌گونه اقدامات باید نسبت به احتمال خطرآفرین بودن درمانجو برای خود یا دیگران
          اطمینان حاصل کند.`
        },
        {
          title: 'اطلاعات شخصی', body: `          در این وبسایت به منظور داشتن ارتباطی مؤثر به اطلاعاتی نظیر سوابق مراجعه به پزشک یا روانشناس، شماره‌ تماس
          مراجعه‌کننده و یکی از اقوام یا نزدیکانش که در شرایط اضطرار بتوان با آنها تماس گرفت، نیاز است. تمامی این
          اطلاعات
          و اطلاعات دیگری که مراجعه‌کننده در اختیار درمانگر قرار می‌دهد، تنها در این وبسایت ذخیره می‌شوند و صرفاً برای
          استمرار و افزایش اثربخشی جلسات مشاوره، از آن‌ها استفاده می‌شود.`
        },
        {
          title: 'انتخاب مشاور و زمان مشاوره', body: `          تمامی مشاوران سیمیاروم از برترین‌های ایران در حوزه تخصصی خود هستند. انتخاب مشاور با توجه به موضوع مشاوره شما و
          حوزه تخصصی درمانگران، توسط تیم تخصصی سیمیاروم انجام شده و زمان مشاوره به شما اطلاع رسانی می گردد.
          در صورتی که برای مشاوره، یکی از درمانگران را ترجیح می‌دهید، پس از ثبت سفارش می توانید به کارشناسان سیمیاروم
          اعلام نمایید تا در انتخاب مشاور شما در نظر بگیرند.
          از زمان درخواست رزرو تا تأیید کارشناسان سیمیاروم و تعیین زمان دقیق جلسه مشاوره، کمتر از 24 ساعت به طول
          می‌انجامد.`
        },
        {
          title: 'ضبط جلسات', body: `          جلسات تصویری و آنلاین به طور کامل خصوصی بوده و هیچ ضبطی از طریق وبسایت سیمیاروم انجام نمی شود. همچنین ضبط
          مکالمات توسط مراجع یا مشاور نیز اکیداً ممنوع است.`
        },
        {
          title: 'موارد اضطراری', body: `          این وبسایت برای حل مسائل بسیار بحرانی به وجود نیامده است؛ لذا در شرایطی که تشخیص روانشناسان سیمیاروم بر حاد
          بودن
          بیماری باشد، مراجعه‌ حضوری و استفاده از سرویس‌های اورژانسی به بیمار توصیه خواهد شد.`
        },
        {
          title: 'پرداخت', body: `          تمامی پرداخت‌ها باید حتما از طریق درگاه پرداخت سیمیاروم که از درگاه‌های امن بین المللی است، انجام شود؛ لذا
          سیمیاروم مسئولیتی در برابر وجوهی که به هر شکل دیگر پرداخت شده‌اند، نخواهد داشت.`
        },
        {
          title: 'کنسل کردن جلسه یا تغییر زمان آن', body: `          برای انتقال زمان جلسه به زمان دیگر، باید حتما تا ۴۸ ساعت قبل از شروع جلسه‌ مشاوره، این موضوع به مسئولان
          هماهنگی
          جلسات اطلاع داده شود. توجه داشته باشید که مبلغ پرداختی قابل بازگشت نبوده و با این کار تنها می‌توان زمان
          مشاوره‌
          را تغییر داد.
          تغییر زمان هر جلسه (با اطلاع رسانی پیش از ۴۸ ساعت) تنها دو بار ممکن است.
          چنانچه تغییر زمان جلسه در فاصله کمتر از ۴۸ ساعت تا یک ساعت قبل از زمان مشاوره از سمت شما اطلاع رسانی شود، جلسه
          شما کنسل می شود و ۵۰٪ هزینه کسر می‌گردد.
          در صورتی که تغییر زمان جلسه کمتر از یک ساعت از زمان جلسه اطلاع داده شود، جلسه شما کنسل و مبلغی عودت داده
          نمی‌شود.
          در صورتی که به هر دلیل اعم از مشکل اتصال اینترنت در ایران یا پیدا شدن مشکل برای مشاور جلسه‌ مشاوره کنسل شود،
          مراجعه‌کننده می‌تواند بین دریافت وجه پرداختی و یا انتقال جلسه‌ مشاوره‌ خود به زمانی دیگر، یک راه را انتخاب
          کند.
          بدیهی است که سیمیاروم مسئولیتی در برابر مشکلاتی که از سوی مراجعه‌کننده سبب کنسل شدن جلسه می‌شود، نخواهد داشت.`
        },
        {
          title: 'گزارش مشکل یا شکایت', body: `          در صورتی که از روند جلسات مشاوره رضایت نداشته یا شکایتی داشته باشید، می‌توانید از طریق راه‌های ارتباطی، پیام
          خود
          را به تیم پشتیبانی سیمیاروم برسانید تا در اسرع وقت رسیدگی کرده و ترتیب اثر دهند.
          موارد فوق بخشی از مهم‌ترین مطالبی است که لازم دانستیم راجع به قوانین مشاوره آنلاین با شما در میان بگذاریم. اگر
          سوالات بیشتری دارید که در این صفحه به پاسخ آن نرسیده‌اید، به این صفحه پرسش‌های متداول مراجعه کرده یا با
          کارشناسان سیمیاروم از طریق راه‌های ارتباطی، تماس بگیرد.
          با تشکر از اعتماد شما
          سیمیاروم`
        },
      ]
    }
  }
}
</script>

<style scoped>
.wizard-modal {

}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  background-color: rgba(0, 0, 0, .3);
  overflow: hidden !important;

}

.bottom-modal {
  position: absolute !important;
  bottom: -80%;
  width: 100%;
  height: 80%;
  border-radius: 20px;
  overflow: hidden;
}

.bottom-modal-header-container {
  width: 100%;
  height: 90px;
  background-color: white;
  border-top-right-radius: 20px!important;
  border-top-left-radius: 20px!important;
  text-align: center;
  padding: 15px 0 0 0;
}

.content-shell {
  height: 80%;
  overflow-y: scroll;
}

.modal-content {
  height: 100%;
  padding: 10px 20px;
  line-height: 25px;
  font-size: .8em;
  background-color: #f7f7f7;
  color: #6d6d6d;
}

.bottom-modal-header-content {
  color: #4597df;
  font-size: 1.2em;
}

.bottom-modal-header-handle {
  width: 50px;
  height: 4px;
  background-color: #dbdbdb;
  border-radius: 3px;
  margin: 0 auto 10px auto;
  cursor: pointer;
}
</style>