<template>
  <div class="text-center " style="width: 100%;!important;position: absolute;bottom: 12%;">
    <v-chip small style="color:#574596;background-color: #d1dcff">
      4 - {{ currentStep - 1 }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      default: 1
    }
  }
}
</script>

<style scoped>

</style>