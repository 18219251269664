<template>
  <v-window v-model="window" :reverse="this.$store.getters.isRTL">
    <v-window-item :value="1">
      <div class="text-center" style="color: #574596">{{$t('$vuetify.onBoarding.wizardLogin[1]')}}</div>
      <v-card-text>
        <v-form class="pa-0" ref="loginForm" @keyup.native.enter="login">
          <v-text-field
              v-model="mutableEmail"
              type="text"
              name="input-10-1"
              :label="$t('$vuetify.emailLabel')"
              required
              :rules="emailRules"
              class="mb-0"
              prepend-inner-icon="far fa-envelope"
          >
          </v-text-field>
          <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-inner-icon="mdi-lock-outline"
              :type="showPassword ? 'text' : 'password'"
              :label="$t('$vuetify.passwordLabel')"
              class="input-group--focused mb-0"
              @click:append="showPassword = !showPassword"
              required
              :rules="passwordRules"
          >
          </v-text-field>


          <div style="width: 100%; text-align: center;margin: 0 auto;" class="mt-3">
            <v-btn
                @click="login"
                :disabled="loginDisabled"
                block
                color="#20b770"
                class="white--text"
                height="50"
                style="border-radius:10px;"
                elevation="0"
                :loading="loginDisabled">
              <span style="font-size: 1.3em;">{{$t('$vuetify.loginLabel')}}</span>
            </v-btn>
          </div>
        </v-form>
        <div class="text-center mt-3">
          <a class="mt-3" style="color: #20b770!important;" @click="window=2">{{$t('$vuetify.loginComponent.resetPassword')}}</a>
        </div>
      </v-card-text>
      <v-card-text class="text-center pt-0 pb-4">

      </v-card-text>
    </v-window-item>
    <v-window-item :value="2">
      <v-card-title class="justify-center">
        <span style="color: #574596">{{ $vuetify.lang.t('$vuetify.accountRecovery') }}</span>
      </v-card-title>
      <v-card-subtitle class="justify-center" style="color: #574566">
        {{ $vuetify.lang.t('$vuetify.accountRecoveryCaption') }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="resetPassword">
          <v-text-field
              @keydown.enter.prevent='resetPassword'
              v-model="emailForResetPassword"
              type="text"
              :label="$vuetify.lang.t('$vuetify.emailLabel')"
              :rules="emailRules"
              prepend-inner-icon="far fa-envelope"
              @submit.stop="resetPassword"
          >
          </v-text-field>
          <v-card-text v-show="checkEmail" class="green--text text-center">{{$t('$vuetify.loginComponent.checkEmail')}}</v-card-text>
          <v-btn
              color="#20b770"
              class="white--text mt-5"
              height="50"
              style="border-radius:10px;"
              elevation="0"

              @click="resetPassword" :disabled="loginDisabled" block>
            <span>{{$t('$vuetify.loginComponent.forgetPassword.action')}}</span>
          </v-btn>
          <div class="justify-center mt-4 text-center">
            {{$t('$vuetify.loginComponent.forgetPassword.loginLabel')}}
            <a @click="window=1" style="color: #20b770!important;">
              {{$t('$vuetify.loginComponent.forgetPassword.loginAction')}}
            </a>
          </div>
        </v-form>
      </v-card-text>
    </v-window-item>
  </v-window>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {

      showPassword: false,
      // email: "",
      mutableEmail: '',
      emailForResetPassword: '',
      checkEmail: false,
      // emailRules: [],
      password: "",
      errorMessages: '',
      name: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      formHasErrors: false,
      window: 1,
      loginErr: {
        show: false,
        msg: '',
      },
      loginDisabled: false,
      registerDisabled: false
    }
  },
  props: {
    GALogin: {
      default: 'ga_login'
    },
    preorderLogin: {
      default: false,
    },
    subtitle: {
      default: 'برای استفاده از پنل کاربری لطفا وارد شوید'
    },
    email: String
  },
  watch: {
    name() {
      this.errorMessages = ''
    },
  },
  computed: {
    passwordRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.Notify.login.password.passwordRequired'),
        v => v.length >= 8 || this.$vuetify.lang.t('$vuetify.Notify.login.password.passwordLengthError')
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ];
    }
  },
  created() {
    this.$store.commit('setLoading', false);
    this.mutableEmail = this.email;
  },
  mounted() {
    // EventBus.$emit('setLoading', true);
  },
  methods: {
    addressCheck() {
      this.errorMessages = this.address && !this.name
          ? 'Hey! I\'m required'
          : ''

      return true
    },
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    resetPassword() {
      if (this.$refs.resetPassword.validate()) {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('resetPassword', {email: this.emailForResetPassword}).then((data) => {

          if (data.type === 'SUCCESS') {
            this.checkEmail = true;
          } else {
            this.checkEmail = false;
          }
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      }
    },
    login() {
      const user = {
        email: this.mutableEmail,
        password: this.password
      };

      if (this.$refs.loginForm.validate()) {
        this.loginDisabled = true;
        EventBus.$emit('setLoading', true);

        this.$store.dispatch("login", user).then((resp) => {

          this.$store.dispatch('getUserInfo').then(() => {
            //WebEngage
            window.webengage.user.login(this.$store.getters.getUserInfo.id);
            this.$store.commit('setLoggedIn');
            EventBus.$emit('userLoggedIn');
            EventBus.$emit('notify', 'green', 3000, this.$t('$vuetify.Notify.loginSuccess'), this.GALogin);
            this.$emit('userLoggedIn');
          }).finally(() => {
            this.loginDisabled = false;
          })
        }).catch((err) => {
          this.loginDisabled = false;
          // console.log('err', err.response.data);
          // if (err.response.data.statusCode === 401 || err.response.data.status === 400) {
          //     this.loginErr = {
          //         show: true,
          //         msg: 'نام کاربری یا کلمه عبور اشتباه است',
          //     }
          // } else if (500 <= err.response.data.statusCode < 600) {
          //     this.$store.commit('setSnackbar', {show: true, type: 'err500'});
          // }
        }).finally(() => {
          EventBus.$emit('setLoading', false);

        });
      }
    },
    changeParentWindow(value) {
      this.$emit('changeWindow', value);
    }
  }
}
</script>

<style scoped>

</style>