<template>
  <div
      class="select-card-shell"
  >
    <v-fade-transition>
      <div class="select-card-check text-center " v-if="info[dataKey] == item.val">
        <v-icon size="18" style="margin-right: 1px;margin-top:-2px;" color="white">mdi-check</v-icon>
      </div>
    </v-fade-transition>
    <div
        @click="selectItem"
        v-ripple
        class="d-flex align-center select-card"
        style="background-color: transparent"
        :class="{'selected':info[dataKey] == item.val}"
    >

      <v-card-text>
        <div class="text-center" v-if="!noImage">
          <img :src="item.image" :alt="item.title" style="width: 100%;">
        </div>
        <div class="text-center select-card-text">
          {{ item.title }}
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noImage: {
      default: null
    },
    info: {},
    item: {
      default: () => ({title: '', image: ''})
    },
    dataKey: {
      default: null
    }
  },
  methods: {
    selectItem() {
      this.$emit('itemSelected', this.dataKey, this.item.val, false)
    }
  }
}
</script>

<style scoped>


.select-card-text {
  transition: all .3s linear;
}

.select-card-shell:hover .select-card-text {
  /*font-weight: bolder;*/
  text-shadow: 0 0 1px rgba(0, 0, 0, .6);
}

.select-card-shell:hover {
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
}

.select-card-check {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #20b770;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.select-card-shell {
  position: relative;
  overflow: hidden;
  transition: all .3s ease-in-out;
  width: 160px;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer
}

@media screen and (max-width: 500px) {
  .select-card-shell {
    width: 45%;
  }
}

.select-card.selected .select-card-text {
  text-shadow: 0 0 1px rgba(0, 0, 0, .6);

}

.select-card.selected {
  border: solid 3px #20b770 !important;
  background-color: white !important;
}

.select-card {

  border-radius: 10px !important;
  border: solid 3px #dbdbdb;
  box-shadow: none;
  max-height: 110px;

}

.bg-white {
  background-color: white;
}
</style>