<template>
  <v-card color="transparent" elevation="0" class="mx-auto step-1">
    <v-card-text class="text-center estedad-font">
      <div>
        <img :src="require('../../assets/new-design/step-1-img.svg')" alt="" class="intro-img">
      </div>
      <div style="font-size:2em;   color: #dbdbdb;" class="mt-5 ">
        {{$t('$vuetify.onBoarding.stepOne.title')}}
      </div>
      <div class="mt-5" style="font-size: 1em">
        {{$t('$vuetify.onBoarding.stepOne.subTitle')}}
      </div>
      <div>
        <v-btn class="mt-5 start-btn" width="150" x-large elevation="0"  @click="start">
          {{$t('$vuetify.onBoarding.stepOne.start')}}
        </v-btn>
      </div>
      <v-btn class="mt-5  " elevation="0" style="  border-radius: 5px;" @click="skip" outlined color="green" small width="150">
          {{$t('$vuetify.onBoarding.stepOne.skip')}}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  methods: {
    start() {
      this.$emit('start')
    },
    skip(){
      this.$emit('skip')
      // this.$router.push({name:'xpreorder',params:{orderId:this.$route.query.package_id || 68}})
    }
  }
}
</script>

<style scoped>


.step1-info::before {
  width: 6px;
  height: 6px;
  position: absolute;

  border-radius: 10px;
  right: -10px;
  top: 8px;
  z-index: 10;
  content: ' ';
  background-color: #707070;
}


.intro-img {
  margin-top: 60px;
  width: 60%;
}

.start-btn {
  height: 35px !important;
  background: #20b770 !important;
  color: white;
  border-radius: 10px;
  transition: background-color .3s linear;
  font-weight: bolder;
  font-size: 1.1em;
}

.start-btn:hover {
  background-color: #daf5eb;
}

.step-1 {
  width: 80%;
}

@media screen and (max-width: 600px) {
  .step-1 {
    width: 90%;
  }
}

</style>